import axios, { AxiosInstance } from "axios";
import { Config } from "../types";

class TenantsAPI {
  httpClient: AxiosInstance;
  config: Config;

  constructor(config: Config) {
    this.config = config;
    this.httpClient = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Token": config.userAuthToken,
      },
    });
  }

  async getTenantDetails() {
    const response: any = await this.httpClient.get(
      `/tenants/${this.config.tenantId}`
    );
    return response.data.tenant;
  }

  async updateTenantDetails(tenant: any) {
    const response: any = await this.httpClient.put(
      `/tenants/${this.config.tenantId}`,
      tenant
    );
    return response.data.tenant;
  }

  async getHoursOfService(tenantId:any) {
    let baseURL;
    if(!process.env.REACT_APP_REACH_API_V3_ENV) {
      baseURL = process.env.REACT_APP_REACH_API_V3_ENDPOINT;
    }
    else if (process.env.REACT_APP_REACH_API_V3_ENV == "production"){
      baseURL = "https://reach24.net/api/v3";
    }
    else {
      baseURL = `https://${process.env.REACT_APP_REACH_API_V3_ENV}.reach24.net/api/v3`;
    }

    const url = `${baseURL}/hours_of_service?tenant_id=${tenantId}`;

    const options = {
      headers: {
        "Content-Type": "application/json",
        "Access-Token": this.config.userAuthToken
      },
    };
  
    const response:any = await axios.get(url, options);
    return response.data.data.hours_of_service;
  }
}

export default TenantsAPI;
