import React, { useRef } from "react";
import { toast } from "react-toastify";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  isFuture,
  isPast,
  isThisHour,
  isToday,
  isWithinInterval,
  add,
  isEqual,
  isThisWeek,
  format,
  getDay
} from "date-fns";
import { Shift, Technician, Config, DateNote, ServiceHours } from "../types";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import NotePopover from "./notes/NotePopover";
const descIcon = require("../images/desc.svg");

function Calendar({
  shifts,
  viewUpdated,
  technicians,
  handleShiftDrop,
  editShift,
  newShift,
  showNewTechnicianModal,
  showEditTechnicianModal,
  showTechNoteModal,
  showDayNoteModal,
  showActivityLogsModal,
  copyPreviousWeek,
  uploadShifts,
  appConfig,
  dateNotes,
  showAuditLogsFeature,
  showNotesFeature,
  disableHeader,
  disableCustomButtons,
  serviceHours
}: {
  shifts: Shift[];
  viewUpdated: (start: Date, end: Date) => void;
  technicians: Technician[];
  handleShiftDrop: (
    shift: Shift,
    newStart: Date | null,
    newEnd: Date | null,
    technician: Technician
  ) => void;
  editShift: (shift: Shift, resource: Technician) => void;
  newShift: (
    resource: Technician,
    start: Date,
    end: Date,
    allDay: boolean
  ) => void;
  showNewTechnicianModal: () => void;
  showEditTechnicianModal: (technician: Technician) => void;
  showTechNoteModal: (technician: any) => void;
  showDayNoteModal: (technician: any) => void;
  showActivityLogsModal: (technician: any) => void;
  copyPreviousWeek: () => void;
  uploadShifts: () => void;
  appConfig: Config;
  dateNotes: DateNote[];
  showAuditLogsFeature: boolean;
  showNotesFeature: boolean;
  disableHeader: boolean;
  disableCustomButtons: boolean;
  serviceHours: ServiceHours
}) {
  const events = shifts.map((shift) => ({
    id: shift.id,
    title: shift.priority,
    start: new Date(shift.startTime),
    end: new Date(shift.endTime),
    resourceId: shift.technicianId || "1", // some shifts dont have technicianId (temp)
    allDay: false,
    editable: isFuture(new Date(shift.endTime)),
    extendedProps: {
      shift: {
        ...shift,
        readOnly: !isFuture(new Date(shift.endTime)),
      },
    },
  }));

  const resources = technicians.map((technician) => ({
    id: technician.id,
    title: `${technician.firstName} ${technician.lastName}`,
    eventColor: technician.labelColor,
    extendedProps: {
      technician: technician,
    },
  }));

  const calendarRef = useRef<FullCalendar>(null!);

  const revertEventResize = ({
    oldEventStart,
    newEventStart,
    newEventEnd,
  }: {
    oldEventStart: Date;
    newEventStart: Date;
    newEventEnd: Date;
  }) => {
    if (isPast(oldEventStart)) {
      return !isEqual(oldEventStart, newEventStart) || isPast(newEventEnd);
    }
    return isPast(newEventStart) || isPast(newEventEnd);
  };

  const isCurrentOrFutureDay = () => {
    const calendarApi = calendarRef.current.getApi();
    const currentDate = calendarApi.getDate();
    return (
      isThisWeek(currentDate, { weekStartsOn: 1 }) || isFuture(currentDate)
    );
  };

  const getSlotBackgroundClass = (date:any,view:any) => {
    if (view.type !== 'resourceTimelineDay') {
      return;
    }

    const weekday = getDay(date);
    const currentTimeSlot = format(date, "HH:mm");
    const currentDateAfterWorkHours = serviceHours?.after_hours?.filter((afterHour:any) => afterHour.day === weekday);
    return currentDateAfterWorkHours?.map((afterHour:any) => {
      if (currentTimeSlot >= afterHour.start_time && currentTimeSlot < afterHour.end_time) {
        return 'after-hours-bg';
      }
    })
  };

  return (
    // @ts-ignore
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, interactionPlugin, resourceTimelinePlugin]}
      schedulerLicenseKey={appConfig.schedulerLicenseKey}
      customButtons={{
        addTechnician: {
          text: "Add technician",
          click: function () {
            !disableCustomButtons && showNewTechnicianModal();
          },
        },
        copyShifts: {
          text: "Copy previous week",
          click: function () {
            !disableCustomButtons && copyPreviousWeek();
          },
        },
        uploadShifts: {
          text: "Upload Shifts",
          click: function () {
            !disableCustomButtons && uploadShifts();
          },
        },
      }}
      headerToolbar={{
        left: "title",
        center: "",
        right: disableHeader
          ? false
          : `${
              appConfig.readOnly
                ? ""
                : `addTechnician uploadShifts ${
                    calendarRef.current && isCurrentOrFutureDay()
                      ? "copyShifts "
                      : ""
                  }`
            }resourceTimelineDay,resourceTimelineWeek today prev next`,
      }}
      displayEventTime={true}
      dayHeaderContent={(args) => {
        return <span>{args.text}</span>;
      }}
      slotLabelContent={(args) => (
        <NotePopover
          args={args}
          showDayNoteModal={showDayNoteModal}
          dateNotes={dateNotes}
          readOnly={appConfig.readOnly}
          showNotesFeature={showNotesFeature}
        />
      )}
      initialView={"resourceTimelineWeek"}
      views={{
        resourceTimelineWeek: {
          resourceAreaHeaderContent: <TechnicianLabel />,
          duration: { weeks: 1 },
          slotDuration: { days: 1 },
          resourceAreaWidth: "15%",
        },
        resourceTimelineDay: {
          resourceAreaHeaderContent: (args) => {
            const calendarApi = calendarRef.current.getApi();
            const currentDate = calendarApi.getDate();
            return (
              <NotePopover
                args={{ ...args, text: "Technicians", date: currentDate }}
                showDayNoteModal={showDayNoteModal}
                dateNotes={dateNotes}
                isResourceAreaHeader={true}
                readOnly={appConfig.readOnly}
                showNotesFeature={showNotesFeature}
              />
            );
          },
          resourceAreaWidth: "15%",
        },
      }}
      firstDay={1}
      editable={true}
      selectable={true}
      aspectRatio={1.8}
      selectMirror={true}
      selectOverlap={false}
      eventOverlap={true}
      dayMaxEventRows={2}
      eventResize={({ event, revert, oldEvent }) => {
        const readOnly =
          appConfig.readOnly ||
          revertEventResize({
            oldEventStart: oldEvent.start as Date,
            newEventStart: event.start as Date,
            newEventEnd: event.end as Date,
          });

        if (readOnly) revert();
        else {
          const resource = event.getResources();
          handleShiftDrop(
            event.extendedProps.shift,
            event.start,
            event.end,
            resource[0].extendedProps.technician
          );
        }
      }}
      eventDrop={({ event, revert, oldEvent }) => {
        const readOnly =
          appConfig.readOnly ||
          isPast(event.start as Date) ||
          isPast(oldEvent.start as Date);
        if (readOnly) revert();
        else {
          const resource = event.getResources();
          handleShiftDrop(
            event.extendedProps.shift,
            event.start,
            event.end,
            resource[0].extendedProps.technician
          );
        }
      }}
      eventClick={({ event }) => {
        if (appConfig.readOnly) return;

        const resource = event.getResources();
        editShift(
          event.extendedProps.shift,
          resource[0].extendedProps.technician
        );
      }}
      select={({ resource, start, end, allDay }) => {
        const readOnly =
          appConfig.readOnly ||
          isPast(new Date(end)) ||
          (isPast(new Date(start)) && !isToday(start)) ||
          (isPast(new Date(start)) &&
            !allDay &&
            !isWithinInterval(new Date(), {
              start: start,
              end: add(start, { minutes: 30 }),
            }));

        if (readOnly) return;

        const startTime =
          (isThisHour(start) &&
            isWithinInterval(new Date(), {
              start: start,
              end: add(start, { minutes: 30 }),
            })) ||
          (isToday(start) && allDay)
            ? new Date()
            : start;

        if (resource !== undefined)
          newShift(resource.extendedProps.technician, startTime, end, allDay);
      }}
      events={events}
      resources={resources}
      datesSet={(dateInfo: any) => viewUpdated(dateInfo.start, dateInfo.end)}
      resourceAreaWidth={120}
      resourceLabelContent={({ resource }) => (
        <ResourceView
          technician={resource.extendedProps.technician}
          showEditTechnicianModal={() =>
            showEditTechnicianModal(resource.extendedProps.technician)
          }
          showTechNoteModal={() =>
            showTechNoteModal(resource.extendedProps.technician)
          }
          showActivityLogsModal={() =>
            showActivityLogsModal(resource.extendedProps.technician)
          }
          readOnly={appConfig.readOnly}
          dateNotes={dateNotes}
          showAuditLogsFeature={showAuditLogsFeature}
          showNotesFeature={showNotesFeature}
        />
      )}
      eventContent={(event) => <EventView event={event} />}
      eventTimeFormat={{
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: true,
        meridiem: "narrow",
      }}
      slotLaneClassNames={(info:any) => getSlotBackgroundClass(info.date,info.view)}
    />
  );
}

function ResourceView({
  technician,
  showEditTechnicianModal,
  showTechNoteModal,
  showActivityLogsModal,
  readOnly,
  dateNotes,
  showAuditLogsFeature,
  showNotesFeature,
}: {
  technician: Technician;
  showEditTechnicianModal: () => void;
  showTechNoteModal: () => void;
  showActivityLogsModal: () => void;
  readOnly: Boolean;
  dateNotes: any;
  showAuditLogsFeature: boolean;
  showNotesFeature: boolean;
}) {
  return (
    <div className="r24tech__col">
      <span
        className="r24tech__color"
        style={{ backgroundColor: technician.labelColor }}
      />{" "}
      <strong>
        {technician.nickName ||
          `${technician.lastName}, ${technician.firstName}`}
      </strong>{" "}
      <NotePopover
        args={{ ...technician, text: "" }}
        showDayNoteModal={showTechNoteModal}
        dateNotes={dateNotes}
        isResourceView={true}
        readOnly={readOnly}
        showNotesFeature={showNotesFeature}
      />
      {technician.phoneNumber && (
        <>
          <br />
          <span className="r24tech__number">{technician.phoneNumber}</span>
        </>
      )}
      {!readOnly && (
        <span>
          <br />
          <button
            type="button"
            className="r24btn r24btn--link"
            onClick={() => {
              showEditTechnicianModal();
            }}
          >
            Edit
          </button>
          {/* use showAuditLogsFeature to toggle History button */}
          <button
            type="button"
            className="r24btn r24btn--link"
            onClick={() => {
              showActivityLogsModal();
            }}
          >
            History
          </button>
        </span>
      )}
    </div>
  );
}

function EventView(props: any) {
  const shiftNotes = props.event.event.extendedProps.shift.notes;
  const shouldDisableEvent = props.event.event.extendedProps.shift.readOnly;

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Shift notes</Popover.Title>
      <Popover.Content>{shiftNotes}</Popover.Content>
    </Popover>
  );

  return (
    <div
      className={shouldDisableEvent ? "fc-event-disable" : "fc-event-enable"}
    >
      <div className="fc-event-inner">
        <div className="fc-event-time">{props.event.timeText}</div>
        <div className="fc-event-pri">Priority: {props.event.event.title}</div>
        {shiftNotes && (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={popover}
          >
            <div
              className="r24tech__desc"
              data-toggle="tooltip"
              data-placement="bottom"
            >
              <img src={descIcon} alt="Notes" />
            </div>
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
}

function TechnicianLabel() {
  return (
    <label className="mb-0" style={{ fontSize: 12 }}>
      Technicians
    </label>
  );
}

export default Calendar;
